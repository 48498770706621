.flag-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 1rem;
    padding: 2rem;
    max-width: 1400px;
    margin: 0 auto;
}
.flag-card {
    position: relative;
    aspect-ratio: 4/3;
    background: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: transform 0.2s, box-shadow 0.2s;
}
.flag-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}
.flag-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.flag-name {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.7);
    color: white;
    padding: 0.5rem;
    font-size: 0.875rem;
    text-align: center;
    transform: translateY(100%);
    transition: transform 0.2s;
}
.flag-card:hover .flag-name {
    transform: translateY(0);
}
@media (max-width: 768px) {
    .flag-grid {
      grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
      gap: 0.5rem;
      padding: 1rem;
    }
    .flag-name {
      font-size: 0.75rem;
    }
  }
.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .iconContainer {
    display: flex;
    gap: 20px;
  }
  
  .icon {
    font-size: 24px; 
    transition: transform 0.3s ease;
    color: #333; 
    border: solid 2px #333;
    padding: 10px 10px;
    border-radius: 50%;
    justify-content: center;
    place-items: center;
    align-items: center;
    display: flex;
  }
  
  .icon:hover {
    transform: scale(1.2); 
    color: #1da1f2;
  }
  
  .copyRight {
    margin-top: 10px;
    font-size: 14px;
    color: #333;
    text-align: center;
  }
  

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
}
.text-logo{
  font-size: 20px;
  color: #3b321d;
}
.button {
  background-color: white;
  padding: 12px 24px;
  border-radius: 8px;
  border: none;
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  color: #6b5b45;
  border: solid 3px #3b321d;
  font-weight: 500;
  align-items: center;
}
.logo {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #ec8415;
  padding: 8px 24px;
  border-radius: 24px;
  border: solid 3px #3b321d;
}
.logo-circle {
  width: 40px;
  height: 40px;
  background-color: #b8d8be;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 3px #3b321d;
}
.logo-text {
  color: #f6f4f2;
  font-weight: bold;
  font-size: 20px;
}
@media (max-width: 768px) {
  .wrapper {
    flex-direction: column;
    gap: 16px;
  }

  .menu-button {
    display: block;
    position: absolute;
    top: 16px;
    right: 16px;
    background: transparent;
    border: none;
    cursor: pointer;
  }
  .mobile-menu {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #f5f1e4;
    padding: 16px;
    width: 100%;
    height: 100%;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }
  .mobile-menu.open {
    transform: translateX(0);
  }
}